<template>
  <div v-if="!loading">
    <b-container>
      <b-row
        align-content="center"
        align-h="center"
        :cols="hasStepGuide ? 6 : 3"
        class="mt-2 py-3"
      >
        <b-col
          cols="12"
          :md="hasStepGuide ? 11 : 7"
          :lg="hasStepGuide ? 9 : 5"
          class="text-center top-up-in-process"
        >
          <div class="header px-2 pb-1">
            <img
              alt="success"
              :src="require('@/assets/images/pages/payment/ic-payment-success-2.svg')"
            >
            <h3 class="my-1">
              {{ $t('addFundPage.creditCardPayment.textTopupSuccess') }}
            </h3>
            <p>{{ $t('addFundPage.creditCardPayment.subTitlePaymentSuccess') }}</p>
          </div>

          <div class="summary p-2 card">
            <h4
              id="title-summary-lianlian"
              data-testid="title-summary-lianlian"
              class="text-left mb-2"
            >
              {{ $t('addFundPage.creditCardPayment.textSummary') }}
            </h4>
            <div class="mb-1 d-flex flex-row flex-wrap justify-content-between">
              <div
                id="summary-lianlian-title-client"
                data-testid="summary-lianlian-title-client"
                class="font-14 text-placeholder"
              >
                {{ $t('addFundPage.creditCardPayment.textClient') }}
              </div>
              <strong
                id="summary-lianlian-current-client-name"
                data-testid="summary-lianlian-current-client-name"
              >
                {{ fullName }}
              </strong>
            </div>
            <div class="mb-1 d-flex flex-row justify-content-between">
              <div
                id="summary-lianlian-title-order-amount"
                data-testid="summary-lianlian-title-order-amount"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.airWallex.labelOrderAmount') }}
              </div>
              <strong
                id="summary-lianlian-current-order-amount"
                data-testid="summary-lianlian-current-order-amount"
              >
                {{ currencyFormat(amount) }} USD
              </strong>
            </div>
            <div class="mb-1 d-flex flex-row justify-content-between">
              <div
                id="summary-lianlian-title-method"
                data-testid="summary-lianlian-title-method"
                class="font-14 text-placeholder"
              >
                {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
              </div>
              <img
                id="summary-lianlian-current-method"
                data-testid="summary-lianlian-current-method"
                :src="lianlianLogo"
                height="16px"
                alt="lianlian"
              >
            </div>

            <div
              v-if="percentFee"
              class="d-flex flex-row justify-content-between mb-1"
            >
              <div
                id="summary-lianlian-title-service-fee"
                data-testid="summary-lianlian-title-service-fee"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
              </div>
              <strong
                id="summary-lianlian-current-service-fee"
                data-testid="summary-lianlian-current-service-fee"
                class="text-primary"
              >{{ currencyFormat(serviceCharge) }} USD</strong>
            </div>

            <div
              v-if="methodFee"
              class="d-flex align-items-center justify-content-between mb-1"
            >
              <div
                id="summary-title-lianlian-fee"
                data-testid="summary-title-lianlian-fee"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.paypal.textPaymentFee', { payment: 'LianLian' }) }}
              </div>
              <div>
                <div
                  class="d-flex align-items-center"
                >
                  <strong
                    id="summary-current-lianlian-fee"
                    data-testid="summary-current-lianlian-fee"
                    class="text-primary"
                  >{{ currencyFormat(lianlianFee) }} USD</strong>
                </div>
              </div>
            </div>

            <div
              v-if="taxRate"
              class="d-flex flex-row justify-content-between mb-1"
            >
              <div
                id="summary-lianlian-title-tax-fee"
                data-testid="summary-lianlian-title-tax-fee"
                class="font-14 text-placeholder"
              >
                {{ $t('payment.balanceTransactionsTable.textTax') }}
              </div>
              <strong
                id="summary-lianlian-current-tax-fee"
                data-testid="summary-lianlian-current-tax-fee"
                class="text-primary"
              >{{ currencyFormat(tax) }} USD</strong>
            </div>

            <div
              v-if="tipAmount > 0"
              class="d-flex align-items-center justify-content-between"
            >
              <div
                id="summary-lianlian-title-tip-fee"
                data-testid="summary-lianlian-title-tip-fee"
                class="font-14 text-placeholder"
              >
                Tip
              </div>
              <strong
                id="summary-lianlian-current-tip-amount"
                data-testid="summary-lianlian-current-tip-amount"
                class="text-primary"
              >{{ currencyFormat(tipAmountTxt) }} USD</strong>
            </div>

            <hr class="hr-custom">

            <div class="d-flex align-items-center justify-content-between">
              <div
                id="summary-lianlian-title-total-amount"
                data-testid="summary-lianlian-title-total-amount"
                class="font-14 text-placeholder text-left"
              >
                {{ $t('addFundPage.creditCardPayment.textTotalAmount') }} = {{ $t('balance.textAmount') }}
                {{ percentFee ? ` + ${$t('payment.balanceTransactionsTable.textServiceCharge')}` : '' }}
                {{ taxRate ? ` + ${$t('payment.balanceTransactionsTable.textTax')}` : '' }}
                {{ methodFee ? ` + ${$t('payment.paypal.textPaymentFee', { payment: 'LianLian' })}` : '' }}
                {{ tipAmount > 0 ? ' + Tip' : '' }}
              </div>
            </div>
            <h3
              id="summary-lianlian-current-total-amount"
              data-testid="summary-lianlian-current-total-amount"
              class="mt-2 text-left"
            >
              {{ currencyFormat(bankTransferAmount) }} USD
            </h3>
          </div>

          <div
            class="action mt-2 d-flex flex-column flex-sm-row justify-content-between align-items-center action-wrap"
          >
            <b-button
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              id="btn-summary-lianlian-recharge"
              data-testid="btn-summary-lianlian-recharge"
              variant="outline-dark"
              class="btn-sm-block btn-recharge"
              pill
              @click="$router.push('/payment/add-fund?tab=lianlian')"
            >
              {{ $t('payment.textRecharge') }}
            </b-button>

            <b-button
              id="btn-summary-lianlian-show-me-ad"
              data-testid="btn-summary-lianlian-show-me-ad"
              variant="primary"
              class="btn-submit w-100 ml-2"
              pill
              @click="$router.history.push('/my-ads-account')"
            >
              {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  BContainer, BRow, BCol, BButton,
} from 'bootstrap-vue'
import _get from 'lodash/get'
import { createNamespacedHelpers } from 'vuex'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import Ripple from 'vue-ripple-directive'
import { toastification } from '@core/mixins/toast'
import { PAYMENT_METHOD } from '@/constants'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
const { mapGetters } = createNamespacedHelpers('payment')

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
  },

  directives: {
    Ripple,
  },

  mixins: [stepGuideMixin, numberFormatMixin, paymentMixin, toastification],

  data() {
    return {
      amount: this.$route.query.amount || '',
      bankTransferAmount: this.$route.query.bankTransferAmount || '',
      paymentMethod: this.$route.query.paymentMethod || '',
      historyTransaction: {},
    }
  },

  computed: {
    ...mapGettersAuth(['user', 'loading', 'generalConfigs']),
    ...mapGetters(['listBusinessAccountHistoryLimit']),

    fullName() {
      const fullName = _get(this.user, ['data', 'fullName'], '')
      const email = _get(this.user, ['data', 'email'], '')
      return fullName || email
    },

    lianlianLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/ic-lianlian-full.svg')
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.lianlian || 0
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.lianlianGate || 0
    },

    serviceCharge() {
      return this.amountFormat(this.percentFee * this.amount)
        .toLocaleString('en-US')
    },

    lianlianFee() {
      return this.amountFormat(this.methodFee * this.amount)
        .toLocaleString('en-US') || 0
    },

    tax() {
      return this.amountFormat(this.taxRate * this.amount)
        .toLocaleString('en-US')
    },

    tipAmount() {
      return this.historyTransaction?.payload?.tipAMAmount || null
    },

    tipAmountTxt() {
      return this.amountFormat(this.tipAmount)
        .toLocaleString('en-US')
    },
  },

  watch: {
    listBusinessAccountHistoryLimit: {
      handler(transactions) {
        if (transactions.paging.total > 0) {
          this.historyTransaction = transactions.content.find(transaction => transaction.paymentMethod === PAYMENT_METHOD.LIANLIAN)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    const { fullPath } = this.$route
    const checkUrl = fullPath.toLowerCase()
      .includes('/payment/lianlian/success')

    if (checkUrl && this.hasStepGuide) {
      this.$router.push({ name: 'my-ads-account' })
      this.toastSuccess(this.$t('payment.toastTopUpSuccess'))
    }
  },
}
</script>

<style lang="scss" scoped>
.container-box {
  max-width: 340px;
  margin: 0 auto;
}

.summary {
  background-color: #fff;
  border-radius: var(--border-radius-base);
  padding: 24px 17px;
}

.btn-recharge {
  font-size: 14px;
  padding: 11px 20px;
  width: 150px;
}

.top-up-in-process {
  max-width: 410px;
  margin: 0;
  padding: 0;
}
</style>
